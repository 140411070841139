<template>
  <b-navbar-nav class="nav">
    <b-nav-item
      v-for="(bookmark, index) in bookmarks"
      :id="`bookmark-${index}`"
      :key="index"
      :to="{ name: bookmark.route }"
      :href="bookmark.href ? bookmark.href : '#'"
      :target="bookmark.href ? '_blank' : '_self'"
    >
      <feather-icon :icon="bookmark.icon" size="21" />
      <b-tooltip
        triggers="hover"
        :target="`bookmark-${index}`"
        :title="bookmark.title"
        :delay="{ show: 300, hide: 50 }"
      />
    </b-nav-item>
    <!-- <b-nav-item-dropdown link-classes="bookmark-star" lazy @hidden="resetsearchQuery">
      <feather-icon slot="button-content" icon="StarIcon" size="21" class="text-warning" />
      <li style="min-width: 300px">
        <div class="p-1">
          <b-form-input
            id="boomark-search-input"
            v-model="searchQuery"
            :placeholder="$t('Explore Deflection Monitoring Software')"
            autofocus
          />
        </div>
        <vue-perfect-scrollbar
          :settings="perfectScrollbarSettings"
          class="search-list search-list-bookmark scroll-area"
          :class="{ show: filteredData.pages && filteredData.pages.length }"
          tagname="ul"
        >
          <b-dropdown-item
            v-for="(suggestion, index) in filteredData.pages || bookmarks"
            :key="index"
            class="suggestion-group-suggestion cursor-pointer"
            link-class="d-flex align-items-center"
            :to="{ name: suggestion.route }"
            @mouseenter="currentSelected = index"
          >
            <feather-icon v-if="suggestion.icon" :icon="suggestion.icon" class="mr-75" size="18" />
            <span class="align-middle">{{ suggestion.title }}</span>
            <feather-icon
              icon="StarIcon"
              class="ml-auto"
              size="16"
              :class="{ 'text-warning': suggestion.isBookmarked }"
              @click.stop.prevent="toggleBookmarked(suggestion)"
            />
          </b-dropdown-item>
          <b-dropdown-item
            v-show="!(filteredData.pages && filteredData.pages.length) && searchQuery"
            disabled
          >
            {{ $t('No Results Found') }}
          </b-dropdown-item>
        </vue-perfect-scrollbar>
      </li>
    </b-nav-item-dropdown> -->
  </b-navbar-nav>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { BNavbarNav, BNavItem, BTooltip } from 'bootstrap-vue'
import useAutoSuggest from '@core/components/app-auto-suggest/useAutoSuggest'
import { ref, watch } from '@vue/composition-api'
import router from '@/router'
import store from '@/store'
import i18n from '@/libs/i18n'
import searchAndBookmarkData from '../search-and-bookmark-data'

export default {
  components: {
    BNavbarNav,
    BNavItem,
    BTooltip,
  },
  setup() {
    const pagesFilter = page =>
      page.isBookmarked && (page.route || page.href) && page.icon
    const searchAndBookmarkDataPages = ref(searchAndBookmarkData.pages)
    const bookmarks = searchAndBookmarkData.pages.data
      .filter(pagesFilter)
      .map(e => ({ ...e, key: e.title, title: i18n.t(e.title) }))
    const storageBookmarks = localStorage.getItem('nav-bookmarks')
    if (storageBookmarks) {
      bookmarks.value = JSON.parse(storageBookmarks)
    }

    const currentSelected = ref(-1)

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
    }

    const { searchQuery, resetsearchQuery, filteredData } = useAutoSuggest({
      data: { pages: searchAndBookmarkDataPages.value },
      searchLimit: 6,
    })
    watch(searchQuery, val => {
      store.commit('app/TOGGLE_OVERLAY', Boolean(val))
    })

    watch(filteredData, val => {
      currentSelected.value = val.pages && !val.pages.length ? -1 : 0
    })

    const suggestionSelected = () => {
      const suggestion = filteredData.value.pages[currentSelected.value]
      router.push({ name: suggestion.route }).catch(() => {})
      resetsearchQuery()
    }

    const toggleBookmarked = item => {
      // Find Index of item/page in bookmarks' array
      const pageIndexInBookmarks = bookmarks.value.findIndex(
        i => i.route === item.route,
      )

      // If index is > -1 => Item is bookmarked => Remove item from bookmarks array using index
      // Else => Item is not bookmarked => Add item to bookmarks' array
      if (pageIndexInBookmarks > -1) {
        bookmarks.value[pageIndexInBookmarks].isBookmarked = false
        bookmarks.value.splice(pageIndexInBookmarks, 1)

        localStorage.setItem('nav-bookmarks', JSON.stringify(bookmarks.value))
      } else {
        bookmarks.value.push(item)
        bookmarks.value[bookmarks.value.length - 1].isBookmarked = true

        localStorage.setItem('nav-bookmarks', JSON.stringify(bookmarks.value))
      }
    }

    return {
      bookmarks,
      perfectScrollbarSettings,
      currentSelected,
      suggestionSelected,
      toggleBookmarked,

      // AutoSuggest
      searchQuery,
      resetsearchQuery,
      filteredData,
    }
  },
  computed: {
    userData() {
      return this.$store.getters['app-common/userData']
    },
  },
  mounted() {
    this.$watch('userData.languageCode', () => {
      this.$nextTick(() => {
        const storageBookmarks = JSON.parse(
          localStorage.getItem('nav-bookmarks'),
        ).map(e => ({
          ...e,
          title: this.$t(e.key),
        }))

        this.bookmarks = storageBookmarks

        localStorage.setItem('nav-bookmarks', JSON.stringify(storageBookmarks))
      })
    })
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
p {
  margin: 0;
}

.nav-bookmar-content-overlay {
  position: fixed;
  opacity: 0;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-transition: all 0.7s;
  transition: all 0.7s;
  z-index: -1;

  &:not(.show) {
    pointer-events: none;
  }

  &.show {
    cursor: pointer;
    z-index: 10;
    opacity: 1;
  }
}
</style>
