export default [
  {
    title: 'Dockings',
    icon: 'HomeIcon',
    route: 'dockings-list',
    authority: 'Measurements_Docking_View',
    isBookmarked: true,
  },
  {
    title: 'Measurements',
    icon: 'BarChart2Icon',
    route: 'measurements-list',
    authority: 'Measurements_Measurement_View',
    isBookmarked: true,
  },
  {
    title: 'Vessels',
    icon: 'AnchorIcon',
    route: 'vessels-list',
    authority: 'General_Vessel_View',
    isBookmarked: true,
  },
  {
    title: 'Administration',
    icon: 'ShieldIcon',
    authority: '',
    children: [
      {
        title: 'Station Parameters',
        icon: 'SettingsIcon',
        route: 'station-parameters-list',
        authority: 'General_StationParameter_View',
      },
      {
        title: 'Main Parameters',
        icon: 'SettingsIcon',
        route: 'main-parameters-list',
        authority: 'General_MainParameter_View',
      },
      {
        title: 'Sensors',
        icon: 'CastIcon',
        route: 'sensors-list',
        authority: 'Station_Management_Sensor_View',
      },
      // {
      //   title: 'Announcements',
      //   icon: 'BellIcon',
      //   route: 'announcements-list',
      //   isBookmarked: true,
      // },
      // {
      //   title: 'Post Categories',
      //   icon: 'ListIcon',
      //   route: 'post-categories-list',
      // },
      {
        title: 'Users',
        icon: 'UsersIcon',
        route: 'users-list',
        authority: 'Users_User_View',
      },
      {
        title: 'User Groups',
        icon: 'UsersIcon',
        route: 'user-groups-list',
        authority: 'Users_User_Group_View',
      },
    ],
  },
]
