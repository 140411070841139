export default function (congressID) {
  return [
    {
      title: 'Dashboard',
      icon: 'HomeIcon',
      route: {
        name: 'congress-edit',
        params: {
          id: congressID,
        },
      },
    },
    {
      title: 'Program Building',
      icon: 'FileTextIcon',
      children: [
        {
          title: 'Registration Periods',
          icon: 'CalendarIcon',
          route: {
            name: 'congress-registration-periods',
            params: {
              id: congressID,
            },
          },
        },
        {
          title: 'Various Services',
          icon: 'SettingsIcon',
          route: {
            name: 'congress-services',
            params: {
              id: congressID,
            },
          },
        },
        {
          title: 'Video Gallery',
          icon: 'FilmIcon',
          route: {
            name: 'congress-videos',
            params: {
              id: congressID,
            },
          },
        },
        {
          title: 'Photo Gallery',
          icon: 'ImageIcon',
          route: {
            name: 'congress-photos',
            params: {
              id: congressID,
            },
          },
        },
        {
          title: 'Topics',
          icon: 'ImageIcon',
          route: {
            name: 'congress-topics',
            params: {
              id: congressID,
            },
          },
        },
      ],
    },
    // {
    //   title: 'Sponsors',
    //   icon: 'GiftIcon',
    //   route: {
    //     name: 'congress-sponsors',
    //     params: {
    //       id: congressID,
    //     },
    //   },
    // },
    // {
    //   title: 'Sessions',
    //   icon: 'MonitorIcon',
    //   route: {
    //     name: 'congress-sessions',
    //     params: {
    //       id: congressID,
    //     },
    //   },
    // },
    {
      title: 'Sessions',
      icon: 'MonitorIcon',
      children: [
        {
          title: 'Session',
          icon: 'MonitorIcon',
          route: {
            name: 'congress-sessions',
            params: {
              id: congressID,
            },
          },
        },
        {
          title: 'Rooms',
          icon: 'ImageIcon',
          route: {
            name: 'congress-rooms',
            params: {
              id: congressID,
            },
          },
        },
        {
          title: 'Faculties',
          icon: 'UsersIcon',
          route: {
            name: 'congress-faculties',
            params: {
              id: congressID,
            },
          },
        },
      ],
    },
    // {
    //   title: 'Abstracts',
    //   icon: 'BookOpenIcon',
    //   route: {
    //     name: 'congress-abstracts',
    //     params: {
    //       id: congressID,
    //     },
    //   },
    // },
    {
      title: 'Registrations',
      icon: 'CreditCardIcon',
      route: {
        name: 'congress-registrations',
        params: {
          id: congressID,
        },
      },
    },
    {
      title: 'E-mails',
      icon: 'InboxIcon',
      children: [
        {
          title: 'Sessions Details',
          route: {
            name: 'congress-emails-sessions-details',
            params: {
              id: congressID,
            },
          },
        },
        {
          title: 'Speaker Invitation',
          route: {
            name: 'congress-emails-speaker-invitation',
            params: {
              id: congressID,
            },
          },
        },
        {
          title: 'Payment Reminder',
          route: {
            name: 'congress-emails-payment-reminder',
            params: {
              id: congressID,
            },
          },
        },
      ],
    },
  ]
}
