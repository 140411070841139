import { getUserRights } from '@/auth/utils'
import menuLinks from '@/navigation/menuLinks'

const rights = getUserRights()
const userMenu = []
menuLinks.forEach(item => {
  if (item.children) {
    const mChildren = []
    item.children.forEach(child => {
      if (rights.includes(child.authority) || !child.authority) {
        mChildren.push(child)
      }
    })
    if (mChildren.length) {
      userMenu.push({
        title: item.title,
        icon: item.icon,
        children: mChildren,
      })
    }
  } else if (rights.includes(item.authority) || !item.authority) {
    userMenu.push(item)
  }
})
export default [...userMenu]
