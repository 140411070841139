<template>
  <div class="nav-item nav-search">
    <!-- Icon -->
    <a
      href="javascript:void(0)"
      class="nav-link nav-link-search"
      @click="openSearchBar"
    >
      <feather-icon icon="SearchIcon" size="21" />
    </a>

    <!-- Input -->
    <div class="search-input" :class="{ open: showSearchBar }">
      <div class="search-input-icon">
        <feather-icon icon="SearchIcon" />
      </div>
      <vue-autosuggest
        v-if="showSearchBar"
        ref="autosuggest"
        v-model="query"
        :suggestions="suggestions"
        :input-props="inputProps"
        :section-configs="sectionConfigs"
        :render-suggestion="renderSuggestion"
        :get-suggestion-value="getSuggestionValue"
        @input="fetchResults"
      />
      <div class="search-input-close" @click="resetSearchQuery()">
        <feather-icon icon="XIcon" />
      </div>
    </div>
  </div>
</template>

<script>
import { VueAutosuggest } from 'vue-autosuggest'
import router from '@/router'
import axios from '@/libs/axios'
import searchAndBookmarkData from '../search-and-bookmark-data'

export default {
  components: {
    VueAutosuggest,
  },
  data() {
    return {
      showSearchBar: false,
      query: '',
      results: [],
      timeout: null,
      selected: null,
      debounceMilliseconds: 250,
      inputProps: {
        id: 'autosuggest__input_ajax',
        placeholder: this.$t('Explore Deflection Monitoring Software'),
        class: 'form-control',
        name: 'ajax',
      },
      suggestions: [],
      sectionConfigs: {
        users: {
          limit: 6,
          label: this.$t('Users'),
          onSelected: (selected) => {
            this.suggestionSelected(selected.item, 'users-edit')
          },
        },
        sensors: {
          limit: 6,
          label: this.$t('Sensors'),
          onSelected: (selected) => {
            this.suggestionSelected(selected.item, 'sensor-edit')
          },
        },
        pages: {
          limit: 6,
          label: this.$t('Pages'),
          onSelected: (selected) => {
            this.selected = selected.item
            router.push({ name: selected.item.route }).catch(() => {})
            this.resetSearchQuery()
          },
        },
      },
    }
  },
  methods: {
    fetchResults() {
      const { query } = this
      if (query.length < 2) {
        return
      }
      this.suggestions = []

      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        const usersPromise = axios.get('users/search', { params: { q: query } })
        const sensorsPromise = axios.get('sensors/search', {
          params: { q: query },
        })

        Promise.all([usersPromise, sensorsPromise]).then((values) => {
          this.selected = null
          const sections = ['users', 'sensors']
          sections.forEach((section, index) => {
            const results = this.filterResults(
              values[index].data,
              query,
              'title'
            )
            if (results.length) {
              this.suggestions.push({ name: section, data: results })
            }
          })
        })
      }, this.debounceMilliseconds)

      this.suggestions.push({
        name: 'pages',
        data: this.filterLocalData(query),
      })
    },
    filterResults(data, text, field) {
      return data.content
        .filter((item) => {
          if (
            item[field] &&
            item[field].toLowerCase().indexOf(text.toLowerCase()) > -1
          ) {
            return item[field]
          }
          return false
        })
        .sort()
    },
    renderSuggestion(suggestion) {
      const { name, item } = suggestion
      return name === 'pages' ? this.$t(item.title) : item.title
    },
    suggestionSelected(item, entityApp) {
      this.selected = item
      router.push({ name: entityApp, params: { id: item.id } }).catch(() => {})
      this.resetSearchQuery()
    },
    getSuggestionValue(suggestion) {
      const { name, item } = suggestion
      return name === 'pages' ? this.$t(item.title) : item.title
    },
    openSearchBar() {
      this.showSearchBar = true
      this.$nextTick(() => {
        this.$refs.autosuggest.$el.querySelector('input').focus()
      })
    },
    resetSearchQuery() {
      this.query = ''
      this.showSearchBar = false
    },
    filterLocalData(query) {
      const key = 'title'
      const { data } = searchAndBookmarkData.pages

      const exactEle = data.filter((item) =>
        this.$t(item[key]).toLowerCase().startsWith(query.toLowerCase())
      )
      const containEle = data.filter(
        (item) =>
          !this.$t(item[key]).toLowerCase().startsWith(query.toLowerCase()) &&
          this.$t(item[key]).toLowerCase().indexOf(query.toLowerCase()) > -1
      )
      return exactEle
        .concat(containEle)
        .slice(0, this.sectionConfigs.pages.limit)
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.autosuggest__results-container {
  position: relative;
  z-index: 999;
  .autosuggest__results {
    position: absolute;
    background-color: $white;
    width: 100%;
    margin-top: 1rem;
    overflow-y: auto;
    border-radius: 0.5rem;
    max-height: 40vh;
    box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.11),
      0 5px 15px 0 rgba(0, 0, 0, 0.08) !important;
    ul {
      padding-left: 0;
      margin-bottom: 0;
      li {
        list-style: none;
        padding: 0.75rem 1rem;
        cursor: pointer;
        &:hover,
        &.autosuggest__results-item--highlighted {
          background: $gray-200;
        }
        .detail {
          line-height: 0.5;
        }
      }
      .autosuggest__results-before {
        color: $primary;
        font-weight: 600;
      }
    }
  }
}
a:hover {
  color: $secondary;
}
//--------- dark layout ---------//
body {
  &.dark-layout {
    .autosuggest__results-container {
      .autosuggest__results {
        background-color: $theme-dark-input-bg;
        color: $theme-dark-body-color;

        // on hover
        .autosuggest__results-item--highlighted,
        .autosuggest__results-item:hover,
        .autosuggest__results-before:hover {
          background-color: $theme-dark-table-hover-bg;
        }
        .my-suggestion-item {
          color: $theme-dark-body-color;
        }
      }
    }
    a {
      color: $secondary;
    }
    a:hover {
      color: $primary;
    }
  }
}
</style>
