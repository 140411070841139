<template>
  <p class="clearfix mb-0">
    <span class="float-md-left d-block d-md-inline-block mt-25">
      COPYRIGHT © {{ new Date().getFullYear() }}
      <b-link
        class="ml-25"
        href="https://www.metrica.gr/"
        target="_blank"
      >
        Metrica
      </b-link>
      <span class="d-none d-sm-inline-block">, All rights Reserved</span>
    </span>

    <b-link
      class="float-md-right d-none d-md-block mr-25"
      :to="{ name: 'changelog' }"
    >
      Version 1.3.0
    </b-link>
  </p>
</template>

<script>
import { BLink } from 'bootstrap-vue'

export default {
  components: {
    BLink,
  },
}
</script>
